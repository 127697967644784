import { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  ColumnLayout,
  Grid,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Header,
  Button,
  Container,
  DatePicker,
  ExpandableSection,
  Select,
  Textarea,
  Popover,
  Modal,
} from "@amzn/awsui-components-react/polaris";

import SpearsApiFactory from '../../spears-api/SpearsApiFactory'
import {
  Metric, SliceSpecification,
  OutputReportParameters,
  CreditMethod,
  AnalysisUnit,
  ResultScope
} from '../../spears-api/generated-src/api';
import {
  dedup,
  formatDate,
  daysBetween,
  getUniqueId,
  METRIC_MAP,
  MARKETPLACE_MAP,
  parseDate,
  getTimezoneOffsetMillis,
  parseRealNumber,
  SLICING_DIMENSION_MAP
} from "../../common/utils";
import TextContent from '@amzn/awsui-components-react/polaris/text-content';

export default function SubmitReportPage() {
  const {cloneReportId} = useParams<{cloneReportId: string}>();
  const SpearsApi = SpearsApiFactory();

  const USE_EXTRA_ARGUMENTS = false;
  const debug_extra_arguments =
`{
  "result_scopes" : [
      { "metrics": [ "ops", "num_adds", "num_clicks", "num_paid_units", "num_paid_purchases"],
        "slice_map" : { "gl_set": ["softlines"], "days_to_deliver": ["dtd_0", "dtd_1", "dtd_0to1"] }
      }
  ]
}`;

  // todo add general support for resultScopes etc.
  const defaultInputReportParameters = {
    description: "",
    weblab_id: "",
    marketplace_id: 0,
    start_date: "",
    end_date: "",
    exclude_dates: [] as string[],
    analysis_unit: AnalysisUnit.Session,
    raw_interleaved_arms: "",
    raw_tommy_barista_unique_join_ratio_threshold: "0.9",
    raw_tommy_barista_match_eligible_ratio_threshold: "0.8",
    extra_arguments: USE_EXTRA_ARGUMENTS ? debug_extra_arguments : '',
  };

  const [inputReportParameters, setInputReportParameters] = useState(defaultInputReportParameters);
  const DEFAULT_METRIC_LIST = ["ops","ops_indicator","num_paid_units"];
  const [slicedScopeMetrics, setSlicedScopeMetrics] = useState<Map<string,boolean>>(
    new Map<string,boolean>(DEFAULT_METRIC_LIST.map(m => [m,true]))
  );
  const [slicedScopeDTD, setSlicedScopeDTD] = useState<Map<string,boolean>>(new Map<string,boolean>());
  const [slicedScopeGL, setSlicedScopeGL] = useState<Map<string,boolean>>(new Map<string,boolean>());
  const [inputErrors, setInputErrors] = useState<Array<string>>([]);
  const [submitErrors, setSubmitErrors] = useState<Array<string>>([]);

  const [actionModalState,setActionModalState] = useState({ dismissible: false, header: "", content: ""});

  function populateScopes(sliceSpace: Array<SliceSpecification>) {
    const newSlicedScopeMetrics = new Map<string,boolean>();
    const newSlicedScope = { gl_set: new Map<string,boolean>(), days_to_deliver: new Map<string,boolean>() };
    for (const sliceSpec of sliceSpace) {
      for(const metric of sliceSpec.metrics) {
        newSlicedScopeMetrics.set(metric, true); // setting metrics is redundant, but functionally correct
        for (const dimension of Object.keys(sliceSpec.slice)) {
          if (dimension in newSlicedScope && Boolean(sliceSpec.slice[dimension])) {
            newSlicedScope[dimension].set(sliceSpec.slice[dimension], true);
          }
        }
      }
    }
    setSlicedScopeMetrics(newSlicedScopeMetrics);
    setSlicedScopeGL(newSlicedScope.gl_set);
    setSlicedScopeDTD(newSlicedScope.days_to_deliver);
  }

  useEffect(() => {
      if (cloneReportId != 'new') {
        // console.log(`Seeding with reportId:'${cloneReportId}'`);
        setWorkingMode(`Copy parameters for report ${cloneReportId}`,false,"Fetching report data...");

        (async () => {
          const clonedParameters = (await SpearsApi.getReportInfo(cloneReportId)).data.report_parameters as OutputReportParameters;
          console.log("clonedParameters",clonedParameters);
          // normalize for old/new interleaved_arms syntax
          const normalizedInterleavedArms = Array.isArray( clonedParameters.interleaved_arms) ?
              clonedParameters.interleaved_arms : Object.keys(new Object(clonedParameters.interleaved_arms));
          const newInputParameters = {...inputReportParameters, ...clonedParameters,
            ...{raw_interleaved_arms: normalizedInterleavedArms.join(" ") || ""},
            ...{raw_tommy_barista_unique_join_ratio_threshold:
                  clonedParameters.tommy_barista_unique_join_ratio_threshold?.toString() ??
                    inputReportParameters.raw_tommy_barista_unique_join_ratio_threshold},
            ...{raw_tommy_barista_match_eligible_ratio_threshold:
                  clonedParameters.tommy_barista_match_eligible_ratio_threshold?.toString() ??
                    inputReportParameters.raw_tommy_barista_match_eligible_ratio_threshold},
            ...{report_id: null}
          }
          setInputReportParameters(newInputParameters);
          populateScopes(clonedParameters.slice_space);
          validateInputReportParameters(newInputParameters);
          setWorkingMode("");
        })();
      } else {
        validateInputReportParameters(inputReportParameters);
        setWorkingMode("");
      }
  }, []); // ensures that this is called only once upon initialization

  function onChange(attribute, value) {
    const newInputReportParameters = { ...inputReportParameters };
    newInputReportParameters[attribute] = value;
    setInputReportParameters(newInputReportParameters);
    console.log("newInputReportParameters", newInputReportParameters)
    validateInputReportParameters(newInputReportParameters);
  };

  function updateStateMap(stateUpdater,attribute,value) {
    stateUpdater(x => (new Map(x)).set(attribute,value));
  }
  function consultStateMap(stateMap,attribute) {
    return stateMap.get(attribute) || false;
  }

  function validateInputReportParameters(parameters) {
    const errors: Array<string> = [];
    function checkNonEmpty(value,label) {
      const canonical_value = (typeof value == "string") ? value.trim() : value;
      if (!canonical_value) { errors.push(`${label} must not be empty`); }
    }
    function checkRatio(value,label) {
      const [parsedValue,parsingErrors] = parseRealNumber(value,[0.,1.]);
      if(parsingErrors.length > 0) { errors.push(`Invalid ${label}: ${JSON.stringify(parsingErrors)}`)}
    }
    checkNonEmpty(parameters.weblab_id,"Weblab Id");
    checkNonEmpty(parameters.marketplace_id,"Marketplace Id");
    checkNonEmpty(parameters.start_date,"Start Date");
    checkNonEmpty(parameters.end_date,"End Date");
    checkNonEmpty(parameters.raw_interleaved_arms,"Interleaved Arms");
    checkRatio(parameters.raw_tommy_barista_unique_join_ratio_threshold,"Tommy/Barista unique join ratio threshold");
    checkRatio(parameters.raw_tommy_barista_match_eligible_ratio_threshold,"Tommy/Barista match-eligible ratio threshold");

    setInputErrors(errors);

    return errors;
  }

  function isDataAvailableForDate(inputDate: Date) : boolean {
    const millisPerHour = 60 * 60 * 1000;
    const millisPerDay = 24 * millisPerHour;
    const m = MARKETPLACE_MAP.get(inputReportParameters.marketplace_id);
    // adjust for target marketplace
    let marketplaceTimezoneOffsetMillis = getTimezoneOffsetMillis("America/Los_Angeles"); // use Pacific by default to be conservative
    if (m != undefined) {
      try {
        marketplaceTimezoneOffsetMillis = getTimezoneOffsetMillis(m.timezone_code);
      } catch (e) {
        console.log(e);
      }
    }
    // all timestamps below are in UTC
    const dailyPartitionEndTimestamp = new Date(inputDate).setUTCHours(0,0,0,0)  + millisPerDay; // data is ready at the end of the day
    const TOMMY_WEBLABS_SLA = (1 + 1 /*extra padding day */ ) * millisPerDay;
    const TOMMY_ASIN_SLA = 36 * millisPerHour;
    const allInputsAvailableAt = dailyPartitionEndTimestamp + marketplaceTimezoneOffsetMillis + Math.max(TOMMY_WEBLABS_SLA, TOMMY_ASIN_SLA);
    const currentTimestamp = Date.now();
    const isAvailable = currentTimestamp >= allInputsAvailableAt ;
    // console.log("inputDate:",inputDate," isAvailable:",isAvailable);
    // console.log("dailyPartitionEndTimestamp:",new Date(dailyPartitionEndTimestamp).toUTCString());
    // console.log("allInputsAvailableAt:",new Date(allInputsAvailableAt).toUTCString());
    // console.log("currentTimestamp:",new Date(currentTimestamp).toUTCString());
    // console.log("delta:", (currentTimestamp - allInputsAvailableAt) / millisPerHour);
    // console.log("timezoneOffset:", marketplaceTimezoneOffsetMillis / millisPerHour);
    return isAvailable;
  }
  function shouldDisableSubmitButton() {
    const shouldDisable = Boolean(actionModalState.header) || inputErrors.length > 0;
    // console.log("shouldDisableSubmitButton:",shouldDisable,"\n",actionModalState,inputErrors.length);
    return shouldDisable ;
  }

  function setWorkingMode(header,dismissible=false,content="") {
    if (header) {
      window.document.body.style.cursor = 'wait';
      setActionModalState({dismissible:dismissible,header:header,content:content});
    } else {
      setActionModalState({dismissible:true,header:"",content:""});
      window.document.body.style.cursor = 'default';
    }
  }

  function parseInterleavedArms(raw_interleaved_arms) {
    const errors : string[] = [];
    const arms = raw_interleaved_arms.split(/[,\s]+/);
    // do some superficial checking for syntax and duplicates
    if (arms.length == 0) { errors.push("Interleaved Arms must not be empty") }
    const validArmPattern = /^T\d+(\=[\w\:]+\>\<[\w\:]+)?$/;
    const malformedArms = arms.filter(x => ! x.match(validArmPattern));
    if (malformedArms.length > 0) { errors.push(`Malformed Interleaved Arms: ${JSON.stringify(malformedArms)}`) }
    const duplicateArms = dedup( arms.filter((e, i, a) => a.indexOf(e) !== i) );
    if (duplicateArms.length > 0) { errors.push(`Duplicate Interleaved Arms: ${JSON.stringify(duplicateArms)}`) }
    return [arms,errors];
  }

  function submitReport() {
    setSubmitErrors([]);

    let extra_arguments;
    if (USE_EXTRA_ARGUMENTS) {
      try {
        extra_arguments = JSON.parse(inputReportParameters.extra_arguments);
      } catch (error) {
        console.error(error);
        setSubmitErrors([...submitErrors,`Error parsing Extra Arguments: ${error}`])
        return;
      }
    }

    const {
      description,
      weblab_id,
      marketplace_id,
      start_date,
      end_date,
      exclude_dates,
      analysis_unit,
      ...remainder
    } = { ...inputReportParameters};

    // parse and validate raw_interleaved_arms
    const [interleaved_arms, armsErrors] = parseInterleavedArms(inputReportParameters.raw_interleaved_arms);
    if (armsErrors.length > 0) { setSubmitErrors([...submitErrors,...armsErrors]); return; }

    // extract report result scopes
    function extractSelectedKeys(map:Map<string,boolean>) : string[] {
      return Array.from(map.keys()).filter( k => map.get(k))
    }

    const metrics = extractSelectedKeys(slicedScopeMetrics);
    const sliced_scope : ResultScope  = {
      metrics : (metrics.length > 0 ? metrics : DEFAULT_METRIC_LIST).map(x => x as Metric),
      slice_map: {}
    };
    const gl_set = extractSelectedKeys(slicedScopeGL);
    const days_to_deliver = extractSelectedKeys(slicedScopeDTD);
    if (gl_set.length > 0) {
      sliced_scope.slice_map!.gl_set = gl_set;
    }
    if (days_to_deliver.length > 0) {
      sliced_scope.slice_map!.days_to_deliver = days_to_deliver;
    }
    const result_scopes = (sliced_scope.metrics.length > 0) ? [sliced_scope] : [];

    const finalParameters = {
      description,
      weblab_id,
      marketplace_id,
      start_date,
      end_date,
      exclude_dates,
      analysis_unit,
      interleaved_arms,
      result_scopes: result_scopes,
      tommy_barista_unique_join_ratio_threshold: parseFloat(inputReportParameters.raw_tommy_barista_unique_join_ratio_threshold),
      tommy_barista_match_eligible_ratio_threshold: parseFloat(inputReportParameters.raw_tommy_barista_match_eligible_ratio_threshold),

      credit_methods: [CreditMethod.AdjustedCreditV2],
      save_intermediate_datasets : [''], // disable saving
      // trigger_source:'tommy_sessions',
      // tommy_data_version:'v2_semianonymized',
      // save_intermediate_datasets : [Date.now().toString()], // TODO disable saving and artificially force new report generation
      // TODO simulate/hardcode for now
      // result_scopes : extra_arguments.result_scopes || {},
      // save_intermediate_datasets : extra_arguments.save_intermediate_datasets || [Date.now().toString()],
    };


    console.log("finalParameters",finalParameters);

    console.log("inputErrors:",inputErrors);

    if (inputErrors.length > 0) {
      return;
    }

    // TODO Remove debug
    // return;


    setWorkingMode(`Submit report`,false,"Working...");

    SpearsApi.submitReport(finalParameters).then(response => {
      // console.log("response", JSON.stringify(response));
      const reportId = response.data;
      setWorkingMode("Submit Report",true,`Successfully submitted report ${reportId}`);
      const refreshURL = `${window.location.origin}/#/view/${reportId}`;
      // console.log("refreshURL:", refreshURL);
      window.location.replace(refreshURL);
    }).catch(error => {
      setWorkingMode("");
      console.log("SpearsApi.submitReport() exception",JSON.stringify(error.response));
      setSubmitErrors([error.response.data.errorMessage]);
    });
  }

  const datePickerProps = {
    nextMonthAriaLabel : "Next month",
    previousMonthAriaLabel : "Previous month",
    todayAriaLabel : "Today"
  };
  const errorList = [...inputErrors, ...submitErrors];

  return (
    <>
      <Modal visible={actionModalState.header?true:false} size="large"
        onDismiss={() => setActionModalState({header:"",content:"",dismissible:true})}
        header={actionModalState.header}
        footer={ actionModalState.dismissible ? <Button variant="primary" onClick={()=>setActionModalState({header:"",content:"",dismissible:true})}>Ok</Button> : <></>} >
        {actionModalState.content}
      </Modal>

      <Box padding={{ top: 'xxl', horizontal: 's', bottom: 'l' }}>
        <Grid gridDefinition={[ {colspan: 12 } ]} >
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="normal" onClick={()=>{window.open("", "_self"); window.close();}}>
                  Close
                </Button>

                <Button variant="primary" onClick={submitReport}
                    disabled={shouldDisableSubmitButton()}>
                  Submit
                </Button>
              </SpaceBetween>
            }
            header={
              <Header variant="h3">
                Specify report parameters
              </Header>
            }
          >
            <Container >
              <SpaceBetween direction="vertical" size="l">
                <Grid gridDefinition={[ {colspan: 3 }, {colspan: 2 }, {colspan: 2 }, {colspan: 5 } ]} >
                  <FormField label="Weblab Id" stretch>
                    <Input
                      value={inputReportParameters.weblab_id}
                      ariaRequired={true}
                      placeholder="Weblab id"
                      onChange={({ detail: { value } }) => onChange('weblab_id', value)}
                    />
                  </FormField>
                  <FormField label="Marketplace Id">
                  <Select
                    placeholder="Marketplace Id"
                    selectedOption={(()=>{
                      const m = MARKETPLACE_MAP.get(inputReportParameters.marketplace_id);
                      return m != undefined ?  { label: m.org.toUpperCase(), value: m.id.toString()} : null ;
                    })()}
                    onChange={({ detail }) => onChange('marketplace_id', parseInt(detail.selectedOption.value || "0")) }
                    options={ Array.from(MARKETPLACE_MAP,
                      ([id,m]) => {return { label: m.org.toUpperCase(), value: id.toString()}}) }
                    filteringType="auto"
                    selectedAriaLabel="Selected"
                  />
                  </FormField>
                  <FormField label="Analysis Unit">
                    <Select
                        placeholder="Analysis Unit"
                        selectedOption={{ label: inputReportParameters.analysis_unit, value: inputReportParameters.analysis_unit}}
                        onChange={({ detail }) => onChange('analysis_unit', detail.selectedOption.value || defaultInputReportParameters.analysis_unit) }
                        options={ Array.from(Object.values(AnalysisUnit), (v,_) => ({ label: v, value: v}))}
                        filteringType="auto"
                        selectedAriaLabel="Selected"
                    />
                  </FormField>
                  <Grid gridDefinition={[ {colspan: 4 }, {colspan: 4 }, {colspan: 2 }]} >
                    <FormField label="Start date">
                      <DatePicker
                        value={inputReportParameters.start_date ? formatDate(parseDate(inputReportParameters.start_date)) : ''}
                        ariaRequired={true}
                        placeholder="Report start date"
                        onChange={({ detail: { value } }) =>
                          onChange('start_date', formatDate(parseDate(value,'yyyy-MM-dd'),"yyyyMMdd"))}
                        isDateEnabled={isDataAvailableForDate}
                        {...datePickerProps}
                      />
                    </FormField>
                    <FormField label="End date">
                      <DatePicker
                        value={inputReportParameters.end_date ? formatDate(parseDate(inputReportParameters.end_date)) : ''}
                        ariaRequired={true}
                        placeholder="Report end date"
                        onChange={({ detail: { value } }) =>
                          onChange('end_date', formatDate(parseDate(value,'yyyy-MM-dd'),"yyyyMMdd"))}
                        isDateEnabled={isDataAvailableForDate}
                        {...datePickerProps}
                      />
                    </FormField>
                    <FormField label="# Days">
                      <Input readOnly disabled value={ !inputReportParameters.start_date || !inputReportParameters.end_date ? '' :
                        (1 + daysBetween(parseDate(inputReportParameters.start_date, "yyyyMMdd"),parseDate(inputReportParameters.end_date,"yyyyMMdd"))).toString()
                      }/>
                    </FormField>
                  </Grid>
                </Grid>
                <FormField label="Interleaved Arms" stretch
                  constraintText="space-, comma-, or new line-separated list of treatments, e.g. : 'T1,T2,T3' or 'T1 T2 T3'">
                  <Grid gridDefinition={[ {colspan: 10 }, {colspan: 2 }]} >
                    <Textarea
                        value={inputReportParameters.raw_interleaved_arms}
                        onChange={({detail: {value}}) => onChange('raw_interleaved_arms', value)}
                        rows={1}
                        autoComplete={false}
                        disableBrowserAutocorrect
                        disableBrowserSpellcheck
                    />
                    <PopulateArmListHelper/>
                  </Grid>
                </FormField>
                <Container header={
                  <FormField label="Metrics" stretch
                  constraintText="Note: The results for 'OPS Indicator' are equivalent to the ones for 'Paid Units Indicator' and 'Paid Purchases Indicator'">
                  </FormField>}
                >
                  <ColumnLayout columns={6}>{
                    [...METRIC_MAP.entries()].map(([metric, info]) => {
                      return <Fragment key={getUniqueId()}>
                        <Checkbox checked={slicedScopeMetrics.get(metric) ?? false}
                                   onChange={e => updateStateMap(setSlicedScopeMetrics, metric, e.detail.checked)}>
                           {info.label}
                        </Checkbox>
                      </Fragment>;
                    })
                  }</ColumnLayout>
                  <Container header='Days To Deliver'>
                    <ColumnLayout columns={6}>{
                      [...SLICING_DIMENSION_MAP.get("days_to_deliver")!.values.entries()].map(([dimensionValue, label]) => {
                        return <Fragment key={getUniqueId()}>
                          <Checkbox checked={slicedScopeDTD.get(dimensionValue) ?? false}
                                    onChange={e => updateStateMap(setSlicedScopeDTD, dimensionValue, e.detail.checked)}>
                            {label}
                          </Checkbox>
                        </Fragment>;
                      })
                    }</ColumnLayout>
                  </Container>
                  <Container header='GL Product Group'>
                    <ColumnLayout columns={6}>{
                      [...SLICING_DIMENSION_MAP.get("gl_set")!.values.entries()].map(([dimensionValue, label]) => {
                        return <Fragment key={getUniqueId()}>
                          <Checkbox checked={slicedScopeGL.get(dimensionValue) ?? false}
                                    onChange={e => updateStateMap(setSlicedScopeGL, dimensionValue, e.detail.checked)}>
                            {label}
                          </Checkbox>
                        </Fragment>;
                      })
                    }</ColumnLayout>
                  </Container>
                </Container>
                <FormField label="Description" stretch>
                    <Textarea placeholder="Enter optional description"
                        value={inputReportParameters.description?? ""}
                        onChange={({detail: {value}}) => onChange('description', value)}
                        rows={1} autoComplete={false} disableBrowserAutocorrect disableBrowserSpellcheck
                    />
                </FormField>
                <ExpandableSection header={"Auxiliary parameters"}>
                  <Grid gridDefinition={[ {colspan: 4 }, {colspan: 4 }]} >
                    <FormField label="Tommy/Barista unique join ratio threshold"  constraintText="real number between 0 and 1">
                      <Input
                        value={inputReportParameters.raw_tommy_barista_unique_join_ratio_threshold}
                        ariaRequired={true}
                        onChange={({ detail: { value } }) => onChange('raw_tommy_barista_unique_join_ratio_threshold', value)}
                      />
                    </FormField>
                    <FormField label="Tommy/Barista match-eligible ratio threshold"  constraintText="real number between 0 and 1">
                      <Input
                        value={inputReportParameters.raw_tommy_barista_match_eligible_ratio_threshold}
                        ariaRequired={true}
                        onChange={({ detail: { value } }) => onChange('raw_tommy_barista_match_eligible_ratio_threshold', value)}
                      />
                    </FormField>
                  </Grid>
                  {USE_EXTRA_ARGUMENTS &&
                      <FormField label="Extra Arguments (optional, to be replaced with real UI)" stretch>
                          <Textarea
                              value={inputReportParameters.extra_arguments}
                              placeholder="Enter JSON-formatted argument values"
                              onChange={({detail: {value}}) => onChange('extra_arguments', value)}
                              rows={12}
                              autoComplete={false}
                              disableBrowserAutocorrect
                              disableBrowserSpellcheck
                          />
                      </FormField>
                  }
                </ExpandableSection>
              </SpaceBetween>
            </Container>
              { (errorList.length == 0) ? '' :
                <Container >
                <TextContent><div style={{color:"red"}}>
                  <h3>Errors:</h3>
                    <ul>
                    {errorList.map(errorMessage => <li key={getUniqueId()}>{errorMessage}</li>)}
                    </ul>
                </div></TextContent>
                </Container>
              }
          </Form>
        </Grid>
      </Box>
    </>
  );

  function PopulateArmListHelper(props) {
    const [value,setValue] = useState("");
    function makeTreatmentList(treatment_count) {
      return [... Array(parseInt(treatment_count)).keys()].map(i => 'T'+(i+1)).join(' ');
    }
   return <Popover
      position="top"
      size="medium"
      triggerType="custom"
      content={
        <>
        <Input value={value} placeholder="Enter number of treatments" onChange={({detail: {value}}) => setValue(value)}/>
        <Button onClick={()=>onChange('raw_interleaved_arms', makeTreatmentList(value))}>OK</Button>
        </>
      }
    >
      <Button>Pre-fill</Button>
    </Popover>;
  }
}
